import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ResourceGroupDefinition,
    ArmTemplateOptions,
    ServiceTemplateOptions,
    SiteTemplateOptions,
    UserInfo,
    ResourceNeedType,
    ApplicationType,
    IngressType,
    BusinessCriticality,
    DeploymentEnvironment,
} from '../../../types/template-request';
import { values, map, apply, prop, pick, isEmpty } from 'ramda';
import { v4 as createUuid } from 'uuid';
import {
    BaseAppArchetype,
    NewAppStep,
    AppArchetype,
    AppArchetypeDetails,
    StepDetail,
    SetAzureOptionsActionPayload,
    SetCiOptionsActionPayload,
    SetSpoGroupsActionPayload,
    SetSecurityGroupsActionPayload,
    InProgressApplicationRequest,
    InProgressPackageRequest,
    InProgressApigeeRequest,
    InProgressSalesforceDxRequest,
    InProgressConsoleAppRequest,
    InProgressRequest,
    CreateProvisioningRequestResponse,
    InProgressProvisioningRequest,
    InProgressApplicationTransferRequest,
    InProgressCloudAccountRequest,
    InProgressAzureTaggingRequest,
    InProgressAzureSecurityGroupsRequest,
    InProgressIamRequest,
    InProgressServicePrincipalRequest,
    InProgressEnterpriseGatewayApiRequest,
    InProgressGitHubRepoRequest,
} from './types';
import { isCiOnlyMode } from './util';
import { fetchDraftProvisioningRequests, createProvisioningRequest } from './api';
import { IAmRequestPrincipalType, IAmRequestScope } from '../../../types/iamRequest';
import { AzureSecurityGroupRequestType, AzureSecurityGroupType } from '../../../types/azureSecurityGroups';
import { ServicePrincipalRequestType } from '../../../types/servicePrincipalRequest';
import {
    EnterpriseGatewayApiEnvironmentInfo,
    EnterpriseGatewayEnvironment,
    EnterpriseGatewayRequestType,
    EnterpriseGatewaySubscriptionType,
} from '../../../types/enterpriseGatewayRequest';
import { AzureTagInfo, TaggingResourceGroup } from '../../../types/azureTagging';
import { ApplicationTransferType } from '../../../types/applicationTransfer';
import { ApimSubscriptionRequestVolume } from '../../../types/enterpriseGatewayRequest';

export interface InProgressProvisioningRequestsState {
    all: { [key: number]: InProgressRequest };
    current: InProgressRequest;
}

const getInitialPackageRequest = (): Partial<InProgressPackageRequest> => ({
    kind: BaseAppArchetype.Package,
});

const getInitialApigeeRequest = (): Partial<InProgressApigeeRequest> => ({
    kind: BaseAppArchetype.Apigee,
});

const getInitialSalesDxRequest = (): Partial<InProgressSalesforceDxRequest> => ({
    kind: BaseAppArchetype.SalesforceDxPackage,
});

const getInitialConsoleAppRequest = (): Partial<InProgressConsoleAppRequest> => ({
    kind: BaseAppArchetype.ConsoleApp,
});

const getInitialApplicationTransferRequest = (): Partial<InProgressApplicationTransferRequest> => ({
    kind: BaseAppArchetype.ApplicationTransfer,
});

const getInitialCloudAccountRequest = (): Partial<InProgressCloudAccountRequest> => ({
    kind: BaseAppArchetype.CloudAccount,
});

const getInitialAzureSecurityGroupsRequest = (): Partial<InProgressAzureSecurityGroupsRequest> => ({
    kind: BaseAppArchetype.AzureSecurityGroups,
});

const getInitialAzureTaggingRequest = (): Partial<InProgressAzureTaggingRequest> => ({
    kind: BaseAppArchetype.AzureTagging,
});

const getInitialIamRequest = (): Partial<InProgressIamRequest> => ({
    kind: BaseAppArchetype.IamRequest,
});

const getInitialServicePrincipalRequest = (): Partial<InProgressServicePrincipalRequest> => ({
    kind: BaseAppArchetype.ServicePrincipalRequest,
});

const getInitialEnterpriseGatewayApiRequest = (): Partial<InProgressEnterpriseGatewayApiRequest> => ({
    kind: BaseAppArchetype.EnterpriseGatewayApi,
});

const getInitialGitHubRepoRequest = (): Partial<InProgressGitHubRepoRequest> => ({
    kind: BaseAppArchetype.GitHubRepo,
});

const getInitialApplicationRequest = (): Partial<InProgressApplicationRequest> => ({
    kind: BaseAppArchetype.AppProvisioning,
    needGitHubRepo: ResourceNeedType.No,
    needDevOpsProject: ResourceNeedType.No,
    needSubscription: ResourceNeedType.No,
    needResourceGroups: ResourceNeedType.No,
    siteAppTemplateOptions: {
        addApprovalStepToSiteDeploymentPipelines: false,
        addCosmosRepository: false,
        addRedux: false,
    },
    serviceAppTemplateOptions: {
        addApiKeyAuthentication: false,
        addApprovalStepToServiceDeploymentPipelines: false,
        addClientCertificateAuthentication: false,
        addCosmosRepository: false,
        addEnterpriseMessagingPublisher: false,
        addEnterpriseMessagingSubscriber: false,
        addFunctions: false,
        addRedundantMessagingClient: false,
        targetFramework: '',
    },
    armTemplateOptions: {
        addApprovalStepToArmDeploymentPipelines: false,
        addCosmosDb: false,
        addEventHub: false,
        addFunctions: false,
        addRedis: false,
        addServiceBus: false,
        addSqlServer: false,
        addAppInsights: false,
        addAppService: false,
        addKeyVault: false,
        addAppConfiguration: false,
        addContainerApp: false,
        addStorageAccount: false,
        addStaticWebsite: false,
        ingressType: IngressType.TrafficManager,
        businessCriticality: BusinessCriticality.Important,
    },
    groups: {},
});

const getInitialRequest = (index: number): InProgressRequest => {
    // TODO: We should get rid of these uses of "null as any"
    return {
        index,
        id: createUuid(),
        appArchetypeDetails: {
            appArchetype: null as any,
            appArchetypeDescription: null as any,
            baseAppArchetype: null as any,
        },
        currentStep: undefined,
        steps: [],
        maxStepIndex: 0,
        submitter: null as any,
        request: null as any,
        props: {},
    };
};

const initialState: InProgressProvisioningRequestsState = {
    all: {},
    current: getInitialRequest(0),
};

const modifyCurrentRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: InProgressProvisioningRequest) => void,
) => {
    if (state.current.request) {
        modifyState(state.current.request);
    }
};

const modifyApplicationRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: InProgressApplicationRequest) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.AppProvisioning:
            modifyState(state.current.request as InProgressApplicationRequest);
            break;
        default:
            break;
    }
};

const modifyPackageRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressPackageRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.Package:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyApigeeRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressApigeeRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.Apigee:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyConsoleAppRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressConsoleAppRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.ConsoleApp:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyGitHubRepoRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressGitHubRepoRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.GitHubRepo:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifySalesforceDxRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressSalesforceDxRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.SalesforceDxPackage:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyApplicationTransferRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressApplicationTransferRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.ApplicationTransfer:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyCloudAccountRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressCloudAccountRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.CloudAccount:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyAzureSecurityGroups = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressAzureSecurityGroupsRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.AzureSecurityGroups:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyAzureTaggingRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressAzureTaggingRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.AzureTagging:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyIamRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressIamRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.IamRequest:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyServicePrincipalRequest = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressServicePrincipalRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.ServicePrincipalRequest:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const modifyEnterpriseGatewayApi = (
    state: InProgressProvisioningRequestsState,
    modifyState: (req: Partial<InProgressEnterpriseGatewayApiRequest>) => void,
) => {
    switch (state.current.request?.kind) {
        case BaseAppArchetype.EnterpriseGatewayApi:
            modifyState(state.current.request);
            break;
        default:
            break;
    }
};

const getNextIndex = (state: InProgressProvisioningRequestsState) => {
    const lastIndex = isEmpty(state.all) ? -1 : apply(Math.max, map(prop('index'), values(state.all)));
    return lastIndex + 1;
};

export const inProgressProvisioningRequestsSlice = createSlice({
    name: 'inProgressProvisioningRequests',
    initialState,
    reducers: {
        setContactEmail: (state, action: PayloadAction<string>) => {
            modifyApplicationRequest(state, req => (req.contactEmail = action.payload));
        },
        setSubmitter: (state, action: PayloadAction<UserInfo>) => {
            state.current.submitter = action.payload;
            modifyApplicationRequest(state, req => (req.submitter = action.payload));
            modifyPackageRequest(state, req => (req.contactEmailAddress = action.payload.email));
            modifyApigeeRequest(state, req => (req.contactEmailAddress = action.payload.email));
            modifySalesforceDxRequest(state, req => (req.contactEmailAddress = action.payload.email));
            modifyConsoleAppRequest(state, req => (req.contactEmailAddress = action.payload.email));
            modifyApplicationTransferRequest(state, req => (req.submitter = action.payload));
            modifyCloudAccountRequest(state, req => (req.submitter = action.payload));
            modifyAzureSecurityGroups(state, req => (req.submitter = action.payload));
            modifyAzureTaggingRequest(state, req => (req.submitter = action.payload));
            modifyIamRequest(state, req => (req.submitter = action.payload));
            modifyServicePrincipalRequest(state, req => (req.submitter = action.payload));
            modifyEnterpriseGatewayApi(state, req => (req.submitter = action.payload));
            modifyGitHubRepoRequest(state, req => (req.submitter = action.payload));
        },
        setManager: (state, action: PayloadAction<UserInfo>) => {
            modifyApplicationRequest(state, req => (req.manager = action.payload));
            modifyCloudAccountRequest(state, req => (req.manager = action.payload));
            modifyAzureSecurityGroups(state, req => (req.manager = action.payload));
            modifyAzureTaggingRequest(state, req => (req.manager = action.payload));
            modifyIamRequest(state, req => (req.manager = action.payload));
            modifyServicePrincipalRequest(state, req => (req.manager = action.payload));
            modifyEnterpriseGatewayApi(state, req => (req.manager = action.payload));
            modifyGitHubRepoRequest(state, req => (req.manager = action.payload));
        },
        setTeamName: (state, action: PayloadAction<string>) => {
            modifyApplicationRequest(state, req => (req.azureTeamName = action.payload));
        },
        setSingleRegion: (state, action: PayloadAction<boolean>) => {
            if (!state.current.props) {
                state.current.props = {};
            }
            state.current.props['singleRegion'] = action.payload;
        },
        setSPOGroupsNeedType: (state, action: PayloadAction<ResourceNeedType | undefined>) => {
            if (!state.current.props) {
                state.current.props = {};
            }
            state.current.props['spoGroupsNeedType'] = action.payload;
        },
        setResourceGroupDefinitions: (state, action: PayloadAction<ResourceGroupDefinition[]>) => {
            modifyApplicationRequest(state, req => {
                req.resourceGroupDefinitions = action.payload;
            });
        },
        setResourceGroupTagging: (state, action: PayloadAction<{ securityLevel: string }>) => {
            modifyApplicationRequest(state, req => {
                req.securityLevel = action.payload.securityLevel;
            });
        },
        setAzureOptions: (state, action: PayloadAction<SetAzureOptionsActionPayload>) => {
            state.current.request = { ...state.current.request, ...action.payload };
        },
        setApplicationShortName: (state, action: PayloadAction<string>) => {
            modifyApplicationRequest(state, req => (req.applicationShortName = action.payload));
            modifyConsoleAppRequest(state, req => (req.applicationShortName = action.payload));
        },
        setApplicationType: (state, action: PayloadAction<ApplicationType>) => {
            modifyApplicationRequest(state, req => (req.applicationType = action.payload));
        },
        setBusinessCriticality: (state, action: PayloadAction<BusinessCriticality>) => {
            modifyApplicationRequest(state, req => (req.armTemplateOptions.businessCriticality = action.payload));
        },
        setNeedResourceGroups: (state, action: PayloadAction<ResourceNeedType>) => {
            modifyApplicationRequest(state, req => (req.needResourceGroups = action.payload));
        },
        setIngressType: (state, action: PayloadAction<IngressType>) => {
            modifyApplicationRequest(state, req => (req.armTemplateOptions.ingressType = action.payload));
        },
        setAddApprovalStep: (state, action: PayloadAction<boolean>) => {
            modifyApplicationRequest(state, req => {
                req.armTemplateOptions.addApprovalStepToArmDeploymentPipelines = action.payload;
                req.siteAppTemplateOptions.addApprovalStepToSiteDeploymentPipelines = action.payload;
                req.serviceAppTemplateOptions.addApprovalStepToServiceDeploymentPipelines = action.payload;
            });
        },
        setResourceProvisioning: (
            state,
            action: PayloadAction<
                Omit<
                    ArmTemplateOptions,
                    'ingressType' | 'businessCriticality' | 'addApprovalStepToArmDeploymentPipelines'
                >
            >,
        ) => {
            modifyApplicationRequest(state, req => {
                req.armTemplateOptions.addCosmosDb = action.payload.addCosmosDb;
                req.armTemplateOptions.addFunctions = action.payload.addFunctions;
                req.armTemplateOptions.addRedis = action.payload.addRedis;
                req.armTemplateOptions.addServiceBus = action.payload.addServiceBus;
                req.armTemplateOptions.addEventHub = action.payload.addEventHub;
                req.armTemplateOptions.addSqlServer = action.payload.addSqlServer;
                req.armTemplateOptions.addAppInsights = action.payload.addAppInsights;
                req.armTemplateOptions.addAppService = action.payload.addAppService;
                req.armTemplateOptions.addContainerApp = action.payload.addContainerApp;
                req.armTemplateOptions.addKeyVault = action.payload.addKeyVault;
                req.armTemplateOptions.addAppConfiguration = action.payload.addAppConfiguration;
                req.armTemplateOptions.addStorageAccount = action.payload.addStorageAccount;
                req.armTemplateOptions.addStaticWebsite = action.payload.addStaticWebsite;

                if (action.payload.addCosmosDb) {
                    req.serviceAppTemplateOptions.addCosmosRepository = true;
                    req.siteAppTemplateOptions.addCosmosRepository = true;
                }

                if (action.payload.addFunctions) {
                    req.serviceAppTemplateOptions.addFunctions = true;
                }
            });
        },
        setSiteTemplateOptions: (state, action: PayloadAction<Partial<SiteTemplateOptions>>) => {
            modifyApplicationRequest(
                state,
                req =>
                    (req.siteAppTemplateOptions = {
                        ...req.siteAppTemplateOptions,
                        ...action.payload,
                    }),
            );
        },
        setServiceTemplateOptions: (state, action: PayloadAction<Partial<ServiceTemplateOptions>>) => {
            modifyApplicationRequest(state, req => {
                if (
                    action.payload.addRedundantMessagingClient &&
                    !req.serviceAppTemplateOptions.addRedundantMessagingClient
                ) {
                    // When the "redundant messaging" option is enabled in code generation, enable the "Service Bus" Azure resource
                    req.armTemplateOptions.addServiceBus = true;
                }

                req.serviceAppTemplateOptions = {
                    ...req.serviceAppTemplateOptions,
                    ...action.payload,
                };
            });
        },
        setSecurityGroups: (state, action: PayloadAction<SetSecurityGroupsActionPayload>) => {
            modifyApplicationRequest(
                state,
                req =>
                    (req.groups = {
                        ...(req.groups ?? {}),
                        ...action.payload,
                    }),
            );
        },
        setSpoGroups: (state, action: PayloadAction<SetSpoGroupsActionPayload>) => {
            modifyApplicationRequest(state, req => {
                const { payload } = action;

                req.groups = req.groups ?? {};
                // TODO: Get rid of these "null as any" statements
                req.groups.devSpoSecurityGroupId = null as any;
                req.groups.devSpoSecurityGroupName = null as any;
                req.groups.qaSpoSecurityGroupId = null as any;
                req.groups.qaSpoSecurityGroupName = null as any;
                req.groups.prodSpoSecurityGroupId = null as any;
                req.groups.prodSpoSecurityGroupName = null as any;

                req.needDevSpoGroup = payload.devSpoInfo?.needSpoGroups ?? ResourceNeedType.No;
                req.needQaSpoGroup = payload.qaSpoInfo?.needSpoGroups ?? ResourceNeedType.No;
                req.needProdSpoGroup = payload.prodSpoInfo?.needSpoGroups ?? ResourceNeedType.No;

                if (req.needDevSpoGroup === ResourceNeedType.Existing) {
                    req.groups.devSpoSecurityGroupId = payload.devSpoInfo?.spoSecurityGroupId;
                    req.groups.devSpoSecurityGroupName = payload.devSpoInfo?.spoSecurityGroupName;
                }
                if (req.needQaSpoGroup === ResourceNeedType.Existing) {
                    req.groups.qaSpoSecurityGroupId = payload.qaSpoInfo?.spoSecurityGroupId;
                    req.groups.qaSpoSecurityGroupName = payload.qaSpoInfo?.spoSecurityGroupName;
                }
                if (req.needProdSpoGroup === ResourceNeedType.Existing) {
                    req.groups.prodSpoSecurityGroupId = payload.prodSpoInfo?.spoSecurityGroupId;
                    req.groups.prodSpoSecurityGroupName = payload.prodSpoInfo?.spoSecurityGroupName;
                }
            });
        },
        setCiOptions: (state, action: PayloadAction<SetCiOptionsActionPayload>) => {
            modifyApplicationRequest(state, req => {
                const { payload } = action;
                req.needDevOpsProject = payload.needDevOpsProject;
                req.AdoGithubServiceAccount = payload.AdoGithubServiceAccount;
                req.devOpsProjectId = payload.devOpsProjectId;
                req.devOpsProjectName = payload.devOpsProjectName;
                req.ciOnlySubscriptionId = payload.subscriptionId;
                req.ciOnlySubscriptionName = payload.subscriptionName;
                req.ciOnlyResourceGroups = payload.resourceGroups;
            });
        },
        setGitHubTeamName: (state, action: PayloadAction<string>) => {
            modifyCurrentRequest(state, req => {
                req.gitHubTeamName = action.payload;
            });
        },
        setGitHubTemplateRepoName: (state, action: PayloadAction<string>) => {
            modifyCurrentRequest(state, req => {
                req.gitHubTemplateRepoName = action.payload;
            });
        },
        setProjectName: (state, action: PayloadAction<string>) => {
            modifyApplicationRequest(state, req => (req.applicationProjectName = action.payload));
            modifyPackageRequest(state, req => (req.projectName = action.payload));
            modifyApigeeRequest(state, req => (req.projectName = action.payload));
            modifyConsoleAppRequest(state, req => (req.applicationProjectName = action.payload));
        },
        setGitHubRepositoryName: (state, action: PayloadAction<string>) => {
            modifyApplicationRequest(state, req => (req.gitHubApplicationRepositoryName = action.payload));
            modifyPackageRequest(state, req => (req.gitHubRepoName = action.payload));
            modifyApigeeRequest(state, req => (req.gitHubRepoName = action.payload));
            modifySalesforceDxRequest(state, req => (req.gitHubRepoName = action.payload));
            modifyConsoleAppRequest(state, req => (req.gitHubRepoName = action.payload));
            modifyEnterpriseGatewayApi(state, req => (req.repositoryName = action.payload));
            modifyGitHubRepoRequest(state, req => (req.gitHubRepositoryName = action.payload));
        },
        setGitHubRepositoryNames: (state, action: PayloadAction<string[]>) => {
            modifyApplicationTransferRequest(state, req => (req.githubRepositories = action.payload));
        },
        setEnterpriseMessagingTransferInfo: (
            state,
            action: PayloadAction<{
                publisherId: string;
                publisherName: string;
                endpointId: string;
                endpointName: string;
                destinationGroupId: string;
                destinationGroupName: string;
            }>,
        ) => {
            modifyApplicationTransferRequest(state, req => {
                req.enterpriseMessaging = req.enterpriseMessaging ?? {};
                req.enterpriseMessaging.publisherId = action.payload.publisherId;
                req.enterpriseMessaging.publisherName = action.payload.publisherName;
                req.enterpriseMessaging.endpointId = action.payload.endpointId;
                req.enterpriseMessaging.endpointName = action.payload.endpointName;
                req.enterpriseMessaging.destinationGroupId = action.payload.destinationGroupId;
                req.enterpriseMessaging.destinationGroupName = action.payload.destinationGroupName;
            });
        },
        setApplicationTransferTypes: (
            state,
            action: PayloadAction<{
                transferTypes: ApplicationTransferType[];
            }>,
        ) => {
            modifyApplicationTransferRequest(state, req => {
                req.transferTypes = action.payload.transferTypes;
            });
        },
        setRequestName: (state, action: PayloadAction<string>) => {
            modifyApplicationRequest(state, req => (req.applicationSlugName = action.payload));
            modifyPackageRequest(state, req => {
                req.packageName = action.payload;
            });
            modifyApigeeRequest(state, req => {
                req.proxyName = action.payload;
            });
            modifySalesforceDxRequest(state, req => {
                req.packageName = action.payload;
            });
            modifyConsoleAppRequest(state, req => {
                req.applicationSlugName = action.payload;
            });
        },
        setProxyTargetServers: (state, action: PayloadAction<{ dev: string; qa: string; prod: string }>) => {
            modifyApigeeRequest(state, req => {
                req.devTargetServerHostName = action.payload.dev;
                req.qaTargetServerHostName = action.payload.qa;
                req.prodTargetServerHostName = action.payload.prod;
            });
        },
        setProxySecurityMechanism: (
            state,
            action: PayloadAction<{
                useClientCertificateAuthentication: boolean;
                useApiKeyAuthentication: boolean;
                connectToOnPremiseDataCenter: boolean;
                useOauth: boolean;
            }>,
        ) => {
            modifyApigeeRequest(state, req => {
                req.useClientCertificateAuthentication = action.payload.useClientCertificateAuthentication;
                req.useApiKeyAuthentication = action.payload.useApiKeyAuthentication;
                req.connectToOnPremiseDataCenter = action.payload.connectToOnPremiseDataCenter;
                req.useOauth = action.payload.useOauth;
            });
        },
        setProxyClientCertificateSubjects: (state, action: PayloadAction<{ test: string; prod: string }>) => {
            modifyApigeeRequest(state, req => {
                req.testClientCertificateSubject = action.payload.test;
                req.prodClientCertificateSubject = action.payload.prod;
            });
        },
        setSalesforceDxConfig: (
            state,
            action: PayloadAction<{
                organizationAlias: string;
                teamSandboxName: string;
                teamSandboxJwtClientId: string;
            }>,
        ) => {
            modifySalesforceDxRequest(state, req => {
                req.organizationAlias = action.payload.organizationAlias;
                req.teamSandboxName = action.payload.teamSandboxName;
                req.teamSandboxJwtClientId = action.payload.teamSandboxJwtClientId;
            });
        },
        setSalesforceDxDevOpsProjectUsers: (
            state,
            action: PayloadAction<{
                devOpsProjectAccessUsers: UserInfo[];
            }>,
        ) => {
            modifySalesforceDxRequest(state, req => {
                req.devOpsProjectAccessUsers = action.payload.devOpsProjectAccessUsers;
            });
        },
        setCloudAccount: (
            state,
            action: PayloadAction<{
                cloudAccount: string;
                cloudAccountDisplayName: string;
            }>,
        ) => {
            modifyCloudAccountRequest(state, req => {
                req.cloudAccount = action.payload.cloudAccount;
                req.cloudAccountDisplayName = action.payload.cloudAccountDisplayName;
            });
        },
        setAzureSecurityGroups: (
            state,
            action: PayloadAction<{
                requestType: AzureSecurityGroupRequestType;
                groupApplicationName: string;
                groupType: AzureSecurityGroupType;
                groupEnvironments: DeploymentEnvironment[];
                azureAADGroupName: string;
                azureAADGroupId: string;
            }>,
        ) => {
            modifyAzureSecurityGroups(state, req => {
                req.requestType = action.payload.requestType;
                req.groupApplicationName = action.payload.groupApplicationName;
                req.groupType = action.payload.groupType;
                req.groupEnvironments = action.payload.groupEnvironments;
                req.azureAADGroupName = action.payload.azureAADGroupName;
                req.azureAADGroupId = action.payload.azureAADGroupId;
            });
        },
        setAzureTaggingRequest: (
            state,
            action: PayloadAction<{
                subscriptionIds?: string[];
                resourceGroups?: TaggingResourceGroup[];
                tags?: AzureTagInfo[];
            }>,
        ) => {
            modifyAzureTaggingRequest(state, req => {
                Object.assign(req, action.payload);
            });
        },
        setIamRequest: (
            state,
            action: PayloadAction<{
                scope: IAmRequestScope;
                subscriptionId: string;
                subscriptionName: string;
                resourceGroupNames: string[];
                roleName: string;
                adGroupName: string;
                adGroupId: string;
                principalType: IAmRequestPrincipalType;
            }>,
        ) => {
            modifyIamRequest(state, req => {
                req.scope = action.payload.scope;
                req.subscriptionId = action.payload.subscriptionId;
                req.subscriptionName = action.payload.subscriptionName;
                req.resourceGroupNames = action.payload.resourceGroupNames;
                req.roleName = action.payload.roleName;
                req.adGroupName = action.payload.adGroupName;
                req.adGroupId = action.payload.adGroupId;
                req.principalType = action.payload.principalType;
            });
        },
        setServicePrincipalRequest: (
            state,
            action: PayloadAction<{
                requestType: ServicePrincipalRequestType;
                spoProjectName: string;
                spoApplicationName: string;
                spoEnvironments: DeploymentEnvironment[];
                associateCertificate: boolean;
                associateFederatedCredential?: boolean;
                servicePrincipalName: string;
                azureAADGroupName: string;
                azureAADGroupId: string;
            }>,
        ) => {
            modifyServicePrincipalRequest(state, req => {
                req.requestType = action.payload.requestType;
                req.spoProjectName = action.payload.spoProjectName;
                req.spoApplicationName = action.payload.spoApplicationName;
                req.spoEnvironments = action.payload.spoEnvironments;
                req.associateCertificate = action.payload.associateCertificate;
                req.associateFederatedCredential = action.payload.associateFederatedCredential;
                req.servicePrincipalName = action.payload.servicePrincipalName;
                req.azureAADGroupName = action.payload.azureAADGroupName;
                req.azureAADGroupId = action.payload.azureAADGroupId;
            });
        },
        setEnterpriseGatewayApiRequest: (
            state,
            action: PayloadAction<
                Partial<{
                    requestType: EnterpriseGatewayRequestType;
                    apiName: string;
                    apimUrlSuffix: string;
                    displayName: string;
                    environments: EnterpriseGatewayApiEnvironmentInfo[];
                    repositoryName: string;
                    gitHubTeamName: string;
                    subscribeEnvironment: EnterpriseGatewayEnvironment;
                    subscriptionType: EnterpriseGatewaySubscriptionType;
                    subscriptionApplicationName: string;
                    subscriptionName: string;
                    apiProductName: string;
                    primaryKey: string;
                    secondaryKey: string;
                    description: string;
                    pointOfContact: string;
                    estimatedVolume: ApimSubscriptionRequestVolume;
                    linkToApp: string;
                    certificateCommonName?: string;
                }>
            >,
        ) => {
            modifyEnterpriseGatewayApi(state, req => {
                Object.assign(req, action.payload);
            });
        },
        setEdomOptions: (
            state,
            action: PayloadAction<{
                needEdom: ResourceNeedType;
                needEdomService: ResourceNeedType;
                needEdomTeam: ResourceNeedType;
                edomServiceId: string;
                edomServiceName: string;
                edomTeamId: string;
                edomTeamName: string;
            }>,
        ) => {
            state.current.request = { ...state.current.request, ...action.payload };
        },
        setPackageInfo: (state, action: PayloadAction<{ description: string; keywords: string }>) => {
            modifyPackageRequest(state, req => {
                req.description = action.payload.description;
                req.keywords = action.payload.keywords;
            });
        },
        setReviewHtml: (state, action: PayloadAction<string>) => {
            modifyCurrentRequest(state, req => {
                req.reviewHtml = action.payload;
            });
        },
        saveInProgressRequestAndCreateNew: state => {
            if (
                (state.current.request?.kind === BaseAppArchetype.AppProvisioning &&
                    state.current.request.applicationSlugName) ||
                (state.current.request?.kind === BaseAppArchetype.Package && state.current.request.packageName) ||
                (state.current.request?.kind === BaseAppArchetype.Apigee && state.current.request.proxyName)
            ) {
                state.all[state.current.index] = state.current;
            }

            state.current = getInitialRequest(getNextIndex(state));
        },
        resumeInProgressRequest: (state, action: PayloadAction<number>) => {
            if (action.payload in state.all) {
                state.current = state.all[action.payload];
            }
        },
        resumeInProgressRequestById: (state, action: PayloadAction<string>) => {
            for (const property in state.all) {
                if (state.all[property].id === action.payload) {
                    state.current = state.all[property];
                }
            }
        },
        removeInProgressRequest: (state, action: PayloadAction<number>) => {
            if (action.payload in state.all) {
                delete state.all[action.payload];
            }
        },
        setAppArchetypeDetails: (state, action: PayloadAction<AppArchetypeDetails>) => {
            state.current.appArchetypeDetails = action.payload;

            // TODO: add compile check when missing type
            switch (action.payload.baseAppArchetype) {
                case BaseAppArchetype.Apigee:
                    state.current.request = getInitialApigeeRequest();
                    break;
                case BaseAppArchetype.Package:
                    state.current.request = getInitialPackageRequest();
                    break;
                case BaseAppArchetype.AppProvisioning:
                    state.current.request = getInitialApplicationRequest();
                    break;
                case BaseAppArchetype.SalesforceDxPackage:
                    state.current.request = getInitialSalesDxRequest();
                    break;
                case BaseAppArchetype.ConsoleApp:
                    state.current.request = getInitialConsoleAppRequest();
                    break;
                case BaseAppArchetype.ApplicationTransfer:
                    state.current.request = getInitialApplicationTransferRequest();
                    break;
                case BaseAppArchetype.CloudAccount:
                    state.current.request = getInitialCloudAccountRequest();
                    break;
                case BaseAppArchetype.AzureTagging:
                    state.current.request = getInitialAzureTaggingRequest();
                    break;
                case BaseAppArchetype.AzureSecurityGroups:
                    state.current.request = getInitialAzureSecurityGroupsRequest();
                    break;
                case BaseAppArchetype.IamRequest:
                    state.current.request = getInitialIamRequest();
                    break;
                case BaseAppArchetype.ServicePrincipalRequest:
                    state.current.request = getInitialServicePrincipalRequest();
                    break;
                case BaseAppArchetype.EnterpriseGatewayApi:
                    state.current.request = getInitialEnterpriseGatewayApiRequest();
                    break;
                case BaseAppArchetype.GitHubRepo:
                    state.current.request = getInitialGitHubRepoRequest();
                    break;
            }

            modifyApplicationRequest(state, req => {
                // Remove all properties except for ingressType and addApprovalStepToArmDeploymentPipelines
                req.armTemplateOptions = pick(
                    ['ingressType', 'addApprovalStepToArmDeploymentPipelines'],
                    req.armTemplateOptions,
                ) as ArmTemplateOptions;

                // Set defaults appropriately
                switch (action.payload.appArchetype) {
                    case AppArchetype.DotNetService:
                        req.applicationType = ApplicationType.Service;
                        req.armTemplateOptions.addAppInsights = true;
                        req.armTemplateOptions.addAppService = true;
                        req.armTemplateOptions.addKeyVault = true;
                        req.armTemplateOptions.addAppConfiguration = true;
                        req.armTemplateOptions.addStorageAccount = true;
                        req.armTemplateOptions.ingressType = IngressType.TrafficManager;
                        break;
                    case AppArchetype.DotNetReactSite:
                        req.applicationType = ApplicationType.Site;
                        req.armTemplateOptions.addAppInsights = true;
                        req.armTemplateOptions.addAppService = true;
                        req.armTemplateOptions.addKeyVault = true;
                        req.armTemplateOptions.addAppConfiguration = true;
                        req.armTemplateOptions.addStorageAccount = true;
                        req.armTemplateOptions.ingressType = IngressType.TrafficManager;
                        break;
                    case AppArchetype.StaticSite:
                        req.applicationType = ApplicationType.StaticSite;
                        req.armTemplateOptions.addStaticWebsite = true;
                        req.armTemplateOptions.ingressType = IngressType.NoIngress;
                        break;
                    case AppArchetype.MinimalApi:
                        req.applicationType = ApplicationType.MinimalApi;
                        req.armTemplateOptions.addAppInsights = true;
                        req.armTemplateOptions.addAppService = true;
                        req.armTemplateOptions.addKeyVault = true;
                        req.armTemplateOptions.addAppConfiguration = false;
                        req.armTemplateOptions.addStorageAccount = false;
                        req.armTemplateOptions.ingressType = IngressType.TrafficManager;
                        break;
                    case AppArchetype.AzureSubscriptionOrResourceGroups:
                        req.needSubscription = ResourceNeedType.New;
                        req.needResourceGroups = ResourceNeedType.New;
                        break;
                    case AppArchetype.AzureResourceGroupsOnly:
                        req.needSubscription = ResourceNeedType.Existing;
                        req.needResourceGroups = ResourceNeedType.New;
                        break;
                    case AppArchetype.ArmTemplate:
                        req.needSubscription = ResourceNeedType.No;
                        req.needResourceGroups = ResourceNeedType.No;
                        req.needDevOpsProject = ResourceNeedType.No;
                        break;
                    case AppArchetype.CloudAccountRequest ||
                        AppArchetype.IamRequest ||
                        AppArchetype.AzureTagging ||
                        AppArchetype.AzureSecurityGroups ||
                        AppArchetype.ServicePrincipalRequest:
                        break;
                    default:
                        break;
                }
            });
        },
        setCurrentStep: (state, action: PayloadAction<NewAppStep>) => {
            state.current.currentStep = action.payload;
        },
        setSteps: (state, action: PayloadAction<StepDetail[]>) => {
            const steps = action.payload;
            state.current.steps = steps;
            const hasAzureSubscriptionStep = !!steps.find(s => s.id === NewAppStep.AzureSubscription);
            const hasCiStep = !!steps.find(s => s.id === NewAppStep.ContinuousIntegration);
            const hasGitStep = !!steps.find(s => s.id === NewAppStep.GitHubCodeGen || s.id === NewAppStep.GitHubTeam);

            modifyApplicationRequest(state, req => {
                if (!hasAzureSubscriptionStep) {
                    req.needSubscription = ResourceNeedType.No;
                    req.needResourceGroups = ResourceNeedType.No;
                }
                if (!hasCiStep) {
                    req.needDevOpsProject = ResourceNeedType.No;
                }

                req.needGitHubRepo = hasGitStep ? ResourceNeedType.New : ResourceNeedType.No;

                if (isCiOnlyMode(state.current)) {
                    req.needDevOpsProject = ResourceNeedType.New;
                }
            });
        },
        gotoNextStep: state => {
            const currentIndex = state.current.steps.findIndex(step => state.current.currentStep === step.id);
            if (currentIndex === -1) {
                throw new Error('Invalid current step');
            }
            if (currentIndex + 1 < state.current.steps.length) {
                state.current.currentStep = state.current.steps[currentIndex + 1].id;
                state.current.maxStepIndex = currentIndex;
            }
        },
        gotoPreviousStep: state => {
            const currentIndex = state.current.steps.findIndex(step => state.current.currentStep === step.id);
            if (currentIndex === -1) {
                throw new Error('Invalid current step');
            }
            if (currentIndex - 1 >= 0) {
                state.current.currentStep = state.current.steps[currentIndex - 1].id;
            }
        },
        gotoSpecificStep: (state, action: PayloadAction<number>) => {
            state.current.currentStep = state.current.steps[action.payload].id;
        },
    },
    extraReducers: {
        [createProvisioningRequest.fulfilled.type]: (
            state,
            action: PayloadAction<CreateProvisioningRequestResponse>,
        ) => {
            if (action.payload.success) {
                if (action.payload.index in state.all) {
                    delete state.all[action.payload.index];
                }

                state.current = getInitialRequest(getNextIndex(state));
            }
        },
        [fetchDraftProvisioningRequests.fulfilled.type]: (state, action: PayloadAction<InProgressRequest[]>) => {
            const validIds = new Set<string>();
            for (let i = 0; i < action.payload.length; i++) {
                const draftRequest = action.payload[i];
                validIds.add(draftRequest.id);

                // For every Draft Request returned by the API, see if we've already got a request with the same ID
                const existing = Object.values(state.all).find(r => r.id === draftRequest.id);
                if (existing) {
                    // If so, update our local copy if the API's version was modified more recently.
                    // Otherwise, ignore
                    if ((existing.modifiedUtc ?? '') < (draftRequest.modifiedUtc ?? '')) {
                        draftRequest.index = existing.index;
                        state.all[existing.index] = draftRequest;
                    }
                } else {
                    // If we don't have a local copy, just go ahead and add it to local state.
                    const index = getNextIndex(state);
                    draftRequest.index = index;
                    state.all[index] = draftRequest;
                }
            }

            const indexes = Object.keys(state.all).map(k => Number(k));
            for (let i = 0; i < indexes.length; i++) {
                const index = indexes[i];
                const id = state.all[index]?.id;
                if (id && !validIds.has(id)) {
                    delete state.all[index];
                }
            }
        },
    },
});
