import React from 'react';
import { ExternalLink } from './ExternalLink';

export function DevExTeamLink(props: { text: string }) {
    return (
        <ExternalLink href="https://teams.microsoft.com/l/channel/19%3a2nqNJcgCYgQLCCPz8fg9Q8oDtwjkvUXqwCgwsgsVCb41%40thread.tacv2/General?groupId=85bd54c1-db12-44bf-a122-12613e1b754f&tenantId=ed38c4bc-a204-4511-8009-34c0612c882a">
            {props.text}
        </ExternalLink>
    );
}
