import { genPageTheme, shapes, createUnifiedTheme, UnifiedTheme } from '@backstage/theme';
import { useTheme } from '@mui/material';
import { OnrampPaletteAdditions, OnrampTheme } from './types';
import { color } from '@horizon/tokens';
type Color = `#${string}`;

export const getOnrampCustomStaticPalette = (themeType: 'dark' | 'light'): OnrampPaletteAdditions => {
    const tokens = themeType === 'light' ? color.light : color.dark;

    return {
        devopsMetrics: {
            elite: '#460587', // non-horizon color
            high: '#28800A', // green 600
            medium: '#FFA700', // yellow 800
            low: tokens.toneCritical as Color, // red 600
            lightText: tokens.textNeutralInverse as Color, // slate 100
            darkText: tokens.textNeutral as Color, // slate 900
        },
        charts: {
            neutral: '#4094E3', // blue 500
            positive: '#3AA612', // green 500
            negative: '#DE1B1B', // red 600
            hues: [
                '#FC6C85',
                '#FFA700', // yellow 800
                '#FCEC3F', // yellow 400
                '#28800A', // green 600
                '#4094E3', // blue 500
                '#460587', // non-horizon color
                '#FCB6B9', // red 300
            ],
        },
        icons: {
            apigee: {
                primary: '#4094E3', // blue 500
                secondary: '#4094E3', // blue 500
                monochrome: '#ABAFB3', // slate 400
            },
            stackOverflow: {
                primary: '#f48024', // non-horizon color
                secondary: '#bcbbbb', // non-horizon color
                monochrome: '#565D65', // slate 700
            },
            apim: {
                primary: '#4094E3', // blue 500
                secondary: '#4094E3', // blue 500
                monochrome: '#ABAFB3', // slate 400
            },
        },
        shortcuts: {
            documentation: '#D61375', // raspberry 600
            default: '#870546', // raspberry 800
            catalog: '#28800A', // green 600
        },
    };
};

const createOnrampTheme = (type: 'dark' | 'light') => {
    const tokens = type === 'light' ? color.light : color.dark;
    const theme = createUnifiedTheme({
        palette: {
            type,
            mode: type,
            background: {
                default: tokens.background, // slate 50
            },
            status: {
                ok: tokens.tonePositive, // green 500
                warning: tokens.toneCaution, // yellow 800
                error: tokens.toneCritical, // red 600
                running: tokens.toneInfo, // blue 600
                pending: '#FCEC3F', // yellow 400
                aborted: '#6B7178', // slate 600
            },
            bursts: {
                fontColor: '#FFFFFF', // non-horizon color
                slackChannelText: '#DEDFE0', // slate 200
                backgroundColor: {
                    default: '#3E464F', // slate 800
                },
                gradient: {
                    linear: 'linear-gradient(-137deg, #6EB3F0 0%, #28800A 100%)', // green 600, blue 400
                },
            },
            primary: {
                main: tokens.textBrand, // blue 600
            },
            banner: {
                info: tokens.textInfo, // blue 600
                error: tokens.textCritical, // red 600
                text: tokens.textNeutral, // non-horizon color
                link: tokens.textInteractive, // blue 1000
            },
            border: tokens.borderNeutral, // slate 200
            textContrast: '#001D3A', // blue 1000
            textVerySubtle: tokens.textSubdued, // slate 200
            textSubtle: tokens.textDisabled, // slate 600
            highlight: '#FFF9C0', // yellow 100
            errorBackground: tokens.toneCriticalFaint, // raspberry 100
            warningBackground: tokens.toneCautionFaint, // yellow 800
            infoBackground: tokens.toneInfoFaint, // blue 50
            errorText: tokens.toneCritical, // red 700
            infoText: tokens.toneInfo, // blue 800
            warningText: tokens.toneCaution, // blue 1000
            linkHover: tokens.toneInteractiveWeak, // blue 500
            link: tokens.toneInteractive,
            gold: '#FFD6D9', // red 200
            navigation: {
                background: '#053361', // blue 900
                indicator: '#DEDFE0', // slate 200
                color: '#ABAFB3', // slate 400
                selectedColor: '#FFFFFF', // non-horizon color
                navItem: {
                    hoverBackground: '#3E464F', // slate 800
                },
                submenu: {
                    background: '#3E464F', // slate 800
                },
            },
            pinSidebarButton: {
                icon: '#001D3A', // blue 1000
                background: '#C8CACC', // slate 300
            },
            tabbar: {
                indicator: '##C8CACC', // slate 300
            },
            ...getOnrampCustomStaticPalette(type),
        },
        defaultPageTheme: 'home',
        pageTheme: {
            home: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // blue 900, blue 500
            documentation: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // slate 500, slate 200
            tool: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.round }), // blue 900, blue 500
            service: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // blue 900, blue 500
            website: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // blue 900, blue 500
            library: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // blue 900, blue 500
            other: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // blue 900, blue 500
            app: genPageTheme({ colors: [tokens.toneInfo, tokens.toneInfo], shape: shapes.wave }), // blue 900, blue 500
            apis: genPageTheme({ colors: [tokens.tonePositive, tokens.tonePositiveWeak], shape: shapes.wave2 }), // green 600, green 400
        },
    });
    return theme;
};

export const lightTheme: UnifiedTheme = createOnrampTheme('light');

export const darkTheme: UnifiedTheme = createOnrampTheme('dark');

export const getOnrampTheme = (): OnrampTheme => {
    const html = document.getElementsByTagName('html')[0];
    const theme = html.getAttribute('data-theme') === 'dark' ? darkTheme : lightTheme;
    return theme.getTheme('v5') as OnrampTheme;
};

export const useOnrampTheme = (): OnrampTheme => {
    return useTheme<OnrampTheme>();
};
