import React, { useEffect, useState } from 'react';
import { Snackbar, Theme } from '@mui/material';
import { OnrampTheme, useOnrampTheme } from '../../../../theme';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme, OnrampTheme>(theme => ({
    default: {
        width: '100%',
        padding: theme.spacing(2),
    },
    success: {
        backgroundColor: onrampTheme => onrampTheme.palette.banner.info,
        color: onrampTheme => onrampTheme.palette.banner.text,
    },
    warning: {
        backgroundColor: onrampTheme => onrampTheme.palette.banner.error,
        color: onrampTheme => onrampTheme.palette.banner.text,
    },
}));

export function Notification(props: { message?: string; type?: 'success' | 'warning'; onComplete?: () => void }) {
    const [alertVisible, setAlertVisible] = useState(false);
    const onrampTheme = useOnrampTheme();
    const styles = useStyles(onrampTheme);

    useEffect(() => {
        if (props.message) {
            setAlertVisible(true);
        }
    }, [props.message, props.type]);

    if (!alertVisible) {
        return null;
    }

    const onCancel = () => {
        setAlertVisible(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.onComplete && props.onComplete();
    };

    return (
        <Snackbar
            onClose={onCancel}
            className={classNames(styles.default, props.type === 'warning' ? styles.warning : styles.success)}
            open
        >
            <div>{props.message}</div>
        </Snackbar>
    );
}
